import * as THREE from "three";
import { Suspense, useRef, useState, useEffect, createRef } from "react";
import { Canvas, useThree, useFrame, useLoader } from "@react-three/fiber";
import { a, useTransition } from "@react-spring/three";
import { useSpring } from "@react-spring/core";
import Particles from "./Particles";
import { useFBO } from "@react-three/drei";
import create from "zustand";
import { MeshRefractionMaterial } from "./MeshRefractionMaterial";
import { RGBELoader } from "three-stdlib";
import { useControls, Leva } from "leva";
import { Perf } from "r3f-perf";
import { EffectComposer, Vignette } from "@react-three/postprocessing";

import {
  Environment,
  RenderTexture,
  Text,
  PerspectiveCamera,
  MeshDistortMaterial
} from "@react-three/drei";

import { LayerMaterial, Displace, Fresnel } from "lamina";
import { FontLoader } from "three-stdlib";

const AnimatedMaterial = a(MeshDistortMaterial);

const LINE_1 = "Miguel Ferreira";
const LINE_2 = "MARRF";
const SIZE = 14;

// made by wojciech dobry
// https://twitter.com/wojciech_dobry

// inspired by pmndrs collective
// https://twitter.com/pmndrs

function Rig(props) {
  const { isMobile } = props;

  /*rotation.current = new DeviceOrientationControls(
    new THREE.PerspectiveCamera()
  );*/
  //rotation.current.connect();
  console.log("olá amigo!");
  const { camera, mouse } = useThree();
  const vec = new THREE.Vector3();
  //console.log("current: ", rotation.current);
  return useFrame(() => {
    camera.position.lerp(
      vec.set(mouse.x * 2, mouse.y * 1, camera.position.z),
      0.02
    );

    //novo
    /*if (!rotation.current) {
      console.log("aqui");
      return;
    } else {
      rotation.current.update();
      //console.log("mas aqui estou2:", rotation.current?.deviceOrientation);

      if (!rotation.current?.deviceOrientation) return;

      const { beta, gamma } = rotation.current.deviceOrientation;
      //console.log("aqui: ", beta, gamma);

      if (!beta || !gamma) return;

      camera.lookAt(0, 0, 0);
      console.log("gamma: ", gamma);
      camera.position.x = -gamma / 90;
      camera.position.y = beta / 90;
      camera.position.z =
        1 -
        0.5 *
          Math.min(
            Math.abs(camera.position.x) + Math.abs(camera.position.y),
            1
          );
    }*/

    //end novo
  });
}

const rotation = createRef();

const Debug = () => {
  const { width } = useThree((s) => s.size);
  return (
    /* This is it -> */
    <Perf
      minimal={width < 712}
      customData={{
        value: 60,
        name: "physic",
        info: "fps"
      }}
    />
  );
};

function InteractionManager(props) {
  return (
    <Suspense>
      <Bubble />
      <Geometries />
      {/*<Bubble1 position={[12, 4, 10]} />
          <Bubble1 position={[-12, -2, 10]} />*/}

      {/*<Teste position={[-20.2, 0, 0]} />*/}
      <Typography />
      <Environment preset="warehouse" />
      <Debug />
    </Suspense>
  );
}

export default function App() {
  const mouse = useRef([0, 0]);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <>
      <Leva hidden />
      <Canvas>
        <PerspectiveCamera position={[0, 0, 50]} makeDefault />
        <InteractionManager isMobile={isMobile} />
        <Particles count={isMobile ? 2000 : 2000} mouse={mouse} />
        {/* <EffectComposer multisampling={0} disableNormalPass={true}>
          <Vignette eskil={false} offset={0.1} darkness={1.1} />
        </EffectComposer> */}
        {/*<gridHelper
          args={[60, 60, "#f01010", "#f50505"]}
          position={[0, 0, 0]}
          rotation={[Math.PI / 4, 0, 0]}
        />
        <TrackballControls />*/}

        {/* <EffectComposer>
          <Scanline density={2.5} />
          <Glitch
            delay={[10.5, 20.5]} // min and max glitch delay
            duration={[0.6, 1.0]} // min and max glitch duration
            strength={[0.1, 0.2]} // min and max glitch strength
            active // turn on/off the effect (switches between "mode" prop and GlitchMode.DISABLED)
            ratio={0.95}
          />
          {/<ChromaticAberration offset={[0.02, 0.002]} />/}
        </EffectComposer>*/}
        <Rig isMobile={isMobile} />
      </Canvas>
      {/* Smoke and mirrors – render invisible DOM above canvas*/}
      <Overlay />
    </>
  );
}

const useStore = create((set) => {
  return {
    items: [
      /*
    {
      position: [-3, 0, 2],
      r: 0.2,
      geometry: new THREE.TetrahedronBufferGeometry(2)
    },
    {
      position: [4.4, -0.75, 4],
      r: 0.3,
      geometry: new THREE.CylinderBufferGeometry(0.8, 0.8, 2, 32)
    },
    {
      position: [-4.8, 0.5, 6],
      r: 0.4,
      geometry: new THREE.ConeGeometry(1.1, 1.7, 32)
    },
    {
      position: [7.5, -1.2, 6],
      r: 0.9,
      geometry: new THREE.SphereBufferGeometry(1.5, 32, 32)
    },
    {
      position: [-7.5, 2.5, 2],
      r: 0.6,
      geometry: new THREE.IcosahedronBufferGeometry(2)
    },*/
      {
        position: [-3, 1.75, 3],
        r: 0.35,
        geometry: new THREE.TorusBufferGeometry(1.1, 0.35, 16, 32)
      },
      {
        position: [3.5, -1.5, 2],
        r: 0.8,
        geometry: new THREE.OctahedronGeometry(2)
      },
      {
        position: [-2, -2.5, 6],
        r: 0.5,
        geometry: new THREE.SphereBufferGeometry(1.5, 32, 32)
      },
      {
        position: [3, 1.9, 1],
        r: 0.2,
        geometry: new THREE.SphereBufferGeometry(2.5, 2.5, 2.5)
      }
    ],
    material: new THREE.MeshStandardMaterial()
  };
});

function Geometry({ r, position, ...props }) {
  const ref = useRef();

  let oldBg;
  const fbo = useFBO(1024);
  const texture = useLoader(
    RGBELoader,
    "https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr"
  );

  useFrame((state) => {
    ref.current.rotation.x = ref.current.rotation.y = ref.current.rotation.z +=
      0.004 * r;
    ref.current.position.y =
      position[1] +
      Math[r > 0.5 ? "cos" : "sin"](state.clock.getElapsedTime() * r) * r;

    //novo:
    /*
    ref.current.visible = false;
    // Set render target to the local buffer
    state.gl.setRenderTarget(fbo);
    // Save the current background and set the HDR as the new BG
    // This is what creates the reflections
    oldBg = state.scene.background;
    state.scene.background = texture;
    // Render into the buffer
    state.gl.render(state.scene, state.camera);
    // Set old state back
    state.scene.background = oldBg;
    state.gl.setRenderTarget(null);
    ref.current.visible = true;
    */
  });
  const { autoRotate, text, shadow, ...config } = useControls({
    text: "Inter",
    clearcoat: { value: 1, min: 0.1, max: 1 },
    clearcoatRoughness: { value: 0.25, min: 0, max: 1 },
    uRefractPower: { value: 0.35, min: 0, max: 5 },
    uTransparent: { value: 0.25, min: 0, max: 5 },
    uIntensity: { value: 1.3, min: 0.0, max: 5.0 },
    uNoise: { value: 0.03, min: 0, max: 1, step: 0.01 },
    uSat: { value: 1.0, min: 1, max: 1.25, step: 0.01 },
    uColor: "#2a000b",
    gColor: "#ff7a7a",
    shadow: "#80446c",
    autoRotate: false
  });

  return (
    <group position={position} ref={ref}>
      <a.mesh {...props}>
        <MeshRefractionMaterial uSceneTex={fbo.texture} {...config} />
      </a.mesh>
    </group>
  );
}

function Geometries() {
  const { items, material } = useStore();
  const { width } = useThree((state) => state.viewport);

  const transition = useTransition(items, {
    from: { scale: [0, 0, 0], rotation: [0, 0, 0] },
    enter: ({ r }) => ({ scale: [1, 1, 1], rotation: [r * 3, r * 3, r * 3] }),
    leave: { scale: [0.1, 0.1, 0.1], rotation: [0, 0, 0] },
    config: { mass: 5, tension: 1000, friction: 100 },
    trail: 100
  });

  //antes era x*3
  return transition((props, { position: [x, y, z], r, geometry }) => (
    <Geometry
      position={[(x * width) / 12, y * 3, z]}
      material={material}
      geometry={geometry}
      r={r}
      {...props}
    />
  ));
}

const Teste = (props) => {
  const sphere = useRef();
  const light = useRef();
  const [mode, setMode] = useState(false);
  const [down, setDown] = useState(false);
  const [hovered, setHovered] = useState(false);

  // Make the bubble float and follow the mouse
  // This is frame-based animation, useFrame subscribes the component to the render-loop

  // Change cursor on hovered state
  useEffect(() => {
    document.body.style.cursor = hovered
      ? "none"
      : `url('data:image/svg+xml;base64,${btoa(
          '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="16" cy="16" r="10" fill="#E8B059"/></svg>'
        )}'), auto`;
  }, [hovered]);

  const [{ wobble, coat, color, ambient, env }] = useSpring(
    {
      wobble: down ? 1.8 : hovered ? 8 : 5,
      coat: mode && !hovered ? 0.04 : 1,
      ambient: mode && !hovered ? 1.5 : 0.5,
      env: mode && !hovered ? 0.4 : 1,
      color: hovered ? "#E8B059" : mode ? "#202020" : "white",
      config: (n) =>
        n === "wobble" && hovered && { mass: 2, tension: 200, friction: 10 }
    },
    [mode, hovered, down]
  );

  return (
    <a.mesh
      {...props}
      ref={sphere}
      scale={wobble}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      onPointerDown={() => setDown(true)}
      onPointerUp={() => {
        setDown(false);
        // Toggle mode between dark and bright
        setMode(!mode);
      }}
    >
      <sphereBufferGeometry args={[1, 64, 64]} />
      <AnimatedMaterial
        color={color}
        envMapIntensity={env}
        clearcoat={coat}
        clearcoatRoughness={0}
        metalness={0.1}
      />
    </a.mesh>
  );
};

const Bubble = () => {
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(0);

  const ref = useRef(null);
  const displaceRef = useRef(null);

  const { width } = useThree((state) => state.viewport);

  useFrame((state, dt) => {
    displaceRef.current.offset.x += 4 * dt;
    /*if (hovered) {
      ref.current.rotation.y += 0.05;
    } else {
      ref.current.rotation.y = 0.0;
    }*/
  });

  const { spring } = useSpring({
    spring: active,
    config: { mass: 5, tension: 400, friction: 50, precision: 0.0001 }
  });
  const rotation = spring.to([0, 1], [0, Math.PI]);

  return (
    <a.mesh
      rotation-y={rotation}
      //onPointerOver={() => setActive(Number(!active))}
      //onPointerOut={() => setActive(Number(!active))}
      ref={ref}
    >
      {/*<sphereBufferGeometry args={[width / 8, 128, 128]} />
       */}
      <torusKnotBufferGeometry args={[5, 3, 20, 16]} />
      <LayerMaterial
        color={"white"}
        lighting={"physical"}
        transmission={1}
        roughness={0.1}
        thickness={2}
      >
        <Fresnel color={"white"} power={0.5} />
        <Displace ref={displaceRef} strength={3} scale={0.25} />
      </LayerMaterial>
    </a.mesh>
  );
};

const Bubble1 = (props) => {
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(0);

  const ref = useRef(null);
  const displaceRef = useRef(null);

  const { width } = useThree((state) => state.viewport);

  useFrame(({ _ }, dt) => {
    displaceRef.current.offset.x += 4 * dt;
    /*if (hovered) {
      ref.current.rotation.y += 0.05;
    } else {
      ref.current.rotation.y = 0.0;
    }*/
  });

  const { spring } = useSpring({
    spring: active,
    config: { mass: 5, tension: 400, friction: 50, precision: 0.0001 }
  });
  const rotation = spring.to([0, 1], [0, Math.PI]);

  return (
    <a.mesh
      {...props}
      rotation-y={rotation}
      onPointerOver={() => setActive(Number(!active))}
      onPointerOut={() => setActive(Number(!active))}
      ref={ref}
    >
      <sphereBufferGeometry args={[width / 8, 128, 128]} />
      {/* <torusKnotBufferGeometry args={[5, 3, 10, 16]} />*/}
      <LayerMaterial
        color={"white"}
        lighting={"physical"}
        transmission={1}
        roughness={0.0}
        thickness={2}
      >
        <Displace ref={displaceRef} strength={3} scale={0.25} />
      </LayerMaterial>
    </a.mesh>
  );
};

const Typography = () => {
  const { width, height } = useThree((state) => state.viewport);
  const vw = (size) => (width * size) / 100;
  const vh = (size) => (height * size) / 100;

  return (
    <mesh>
      <planeBufferGeometry args={[width, height, 1]} />
      <meshBasicMaterial>
        {/* AFAIK :  */}
        {/* Rendering text into a texture gives the best quality */}
        <RenderTexture attach="map">
          <Text
            font="/PlayfairDisplay-Regular.ttf"
            fontSize={vw(SIZE / 7)}
            position={[0, vh(10), 0]}
          >
            {LINE_1}
          </Text>
          <Text
            font="/Roboto-Black.ttf"
            fontSize={vw(SIZE)}
            position={[0, 0, 0]}
          >
            {LINE_2}
          </Text>
        </RenderTexture>
      </meshBasicMaterial>
    </mesh>
  );
};

const Overlay = () => (
  <>
    <span
      style={{
        position: "absolute",
        bottom: "50%",
        left: "50%",
        fontSize: `${SIZE / 7}vw`,
        fontFamily: "Playfair Display",
        fontWeight: 200,
        lineHeight: 1,
        color: "hsla(0, 100%, 50%, 0)",
        transform: "translateX(-50%) translateY(calc(50% - 10vh))",
        whiteSpace: "nowrap"
      }}
    >
      {LINE_1}
    </span>
    <span
      style={{
        position: "absolute",
        bottom: "50%",
        left: "50%",
        fontSize: `${SIZE}vw`,
        fontFamily: "Roboto",
        fontWeight: 900,
        lineHeight: 1,
        color: "hsla(0, 100%, 50%, 0)",
        transform: "translateX(-50%) translateY(50%)",
        whiteSpace: "nowrap"
      }}
    >
      {LINE_2}
    </span>
    {/*<span
      style={{
        position: "absolute",
        bottom: "10%",
        left: "50%",
        fontSize: `1.75vw`,
        fontFamily: "IBM Plex Sans",
        fontWeight: 200,
        color: "hsla(0, 0%, 95%, 1)",
        transform: "translateX(-50%) translateY(50%)",
        whiteSpace: "nowrap",
        textAlign: "center"
      }}
    >
      made by{" "}
      <a
        href="https://twitter.com/wojciech_dobry"
        target="_blank"
        rel="noreferrer"
      >
        wojciech dobry
      </a>{" "}
      – inspired by{" "}
      <a href="https://twitter.com/pmndrs" target="_blank" rel="noreferrer">
        pmndrs
      </a>{" "}
      collective
    </span>*/}
  </>
);
